function clean() {
    $('.feedback .alert-success').html('').hide();
    $('.feedback .alert-danger').html('').hide();
    $('.ajax-feedback .alert-success').html('').hide();
    $('.ajax-feedback .alert-danger').html('').hide();
}

function feedback(feedback, ajax) {
    clean();
    var wrapper = '.feedback';
    if (ajax) wrapper = '.ajax-feedback';

    if (feedback.code == 200)$(wrapper + ' .alert-success').html(feedback.message).show();
    if (feedback.code == 500)$(wrapper + ' .alert-danger').html(feedback.message).show();
}

jQuery(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});